import React, { useEffect, useState } from "react";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import StarRatingComponent from "react-star-rating-component";
import { Counter } from "../../MonCompte/MonComptePage/Panier/PanierPage";
import { FaShoppingCart } from "react-icons/fa";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import GaleryProduct, { images } from "./GaleryProduct";
import AvisProduct from "./AvisProduct";
import ProductSimilary from "./ProductSimilary";
import { useParams } from "react-router-dom";
import { useLazyProductBySlugQuery } from "../../../utils/api/product/product.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { AppStorage } from "../../../utils/storage";
import { Color } from "../../../utils/theme";
import Swal from "sweetalert2";
import { onSetPanier } from "../../../redux/slice/User.slice";
import Fade from "react-reveal/Fade";
import { addCartProduct } from "../../../redux/slice/useCartSlice";
import {
  useAddProductCartMutation,
  useGetVendeurByApiKeyQuery,
} from "../../../utils/api/product/product.api";
import { useLazyFavoriteByUserQuery } from "../../../utils/api/favories/favorie.api";
import { ApiBaseUrl } from "../../../utils/http";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { formatCurrency } from "../../../utils/Utils";
import { VendeurApikey } from "../../../utils/http";
import { getVendeur } from "../../HomePage/HomePage";

const ProduitItemPage = () => {
  const Vendeur = getVendeur();

  // const [item] = useProductFromLocation();
  const dispatch = useAppDispatch();

  const [item] = images;

  const { slug } = useParams<{ slug: string }>();
  const { user, token } = useAppSelector((s) => s?.user);
  const [showProfil, setShowProfil] = useState(true);
  const [variationActive, setVariationActive] = useState<any>();
  const [variations, setVariations] = useState<any>([]);
  const [validation, setValidation] = useState<boolean>(false);
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [imagesProduit, setImagesProduit] = useState<any>();
  const [tailles, setTailles] = useState<any>();
  const [tailleActive, setTailleActive] = useState<any>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyProductBySlugQuery();
  const [findByFavorisSlug, { data: favoris }] = useLazyFavoriteByUserQuery();
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const navigate = useNavigate();
  const [count, setCount] = React.useState(1);
  const [addToCart] = useAddProductCartMutation();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
      findByFavorisSlug(user?.slug as string);
    }
  }, [slug]);

  const handalevalidation = () => {
    setValidation(false);
    setShowValidation(true);

    setTimeout(() => {
      setShowValidation(false);
    }, 3000);
  };

  useEffect(() => {
    if (
      data?.type == "taille_unique" ||
      data?.type == "taille_unique_avec_couleur"
    ) {
      setValidation(true);
      setShowValidation(false);
    }

    if (data?.variations[0]) {
      setVariationActive(data?.variations[0]);
      setVariations([data?.variations[0]]);
    } else {
      setVariationActive(null);
      setVariations([]);
    }
  }, [data]);

  useEffect(() => {
    setImagesProduit(variationActive?.images);
    setTailles(
      data?.variations?.filter(
        (x) => x.couleur == variationActive?.couleur && x.quantite > 0
      )
    );
  }, [variationActive]);

  function SuccessPopup() {
    Swal.fire({
      icon: "success",
      iconColor: Color.success,
      confirmButtonColor: Color.success,
      title: "Produit ajouté au panier avec succès !",
      showConfirmButton: false,
      timer: 3000,
    });
  }

  // Fonction pour ajouter un produit au panier
  const ajouterAuPanier = async () => {
    findBySlug(slug as string);

    let taux =
      data?.categorie?.promotion?.taux ||
      data?.sous_categorie?.promotion?.taux ||
      data?.promotion?.taux;

    let produit = {
      produit: data,
      prix:
        data?.promotion ||
          data?.categorie?.promotion ||
          data?.sous_categorie?.promotion
          ? data?.prix - (data?.prix * taux) / 100
          : parseInt(data?.prix),
      quantite: count,
      stock: data?.stock,
      variations: variations,
      vendeur: data?.vendeur?.id,
    };

    dispatch(addCartProduct(produit));
    SuccessPopup();
  };

  const onSelectColor = (item) => {
    setVariationActive(item);
    setVariations([item]);
    if (
      data?.type == "taille_unique" ||
      data?.type == "taille_unique_avec_couleur"
    ) {
      setValidation(true);
    } else {
      setValidation(false);
    }
  };

  const onSelectSize = (item) => {
    setTailleActive(item?.taille);
    setVariations([item]);
    setValidation(true);
  };

  const ColorVariationItem = ({
    color,
    isActive,
    variation,
  }: {
    color: string;
    isActive?: boolean;
    variation?: any;
  }) => {
    return (
      <div
        className={
          variationActive?.id == isActive ? "btn_color_wrapper" : "btn_color"
        }
      >
        <label
          role="button"
          className={`btn btn_color_choose`}
          style={{ background: color }}
          onClick={() => onSelectColor(variation)}
        ></label>

        <input
          type="radio"
          name="variation_color"
          id="variation_color"
          value={variation?.id}
        />
      </div>
    );
  };

  const SizeVariationItem = ({
    size,
    variation,
  }: {
    size: string;
    variation: any;
  }) => {
    return (
      <label
        role="button"
        className={
          variation?.taille == tailleActive
            ? "btn btn_size_choose active "
            : "btn btn_size_choose"
        }
        onClick={() => onSelectSize(variation)}
      >
        <input
          type="radio"
          name="variation_size"
          id="variation_size"
          value={variation?.id}
        />

        {size}
      </label>
    );
  };

  const decrement = (e) => {
    console.log("decrement", e.target.value);
    setCount((prevState) => prevState - 1);
  };

  const increment = (e) => {
    console.log("increment", e.target.value);
    setCount((prevState) => prevState + 1);
  };

  const handleAddFavoris = (id) => {
    let favorie = favoris?.find((x) => x?.produit?.id == id);

    let fd = new FormData();

    fd.append("user", user?.id);
    fd.append("produit", id);

    if (favorie) {
      axios
        .delete(ApiBaseUrl + `/api/favoris/${favorie?.slug}`, config)
        .then((response) => {
          findBySlug(slug as string);
          findByFavorisSlug(user?.slug as string);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    } else {
      axios
        .post(ApiBaseUrl + "/api/favoris/", fd, config)
        .then((response) => {
          findBySlug(slug as string);
          findByFavorisSlug(user?.slug as string);
        })
        .catch((error) => {
          console.error(`Error: ${error}`);
        });
    }
  };

  const onToggleFavorite = (id) => {
    if (!user?.slug) {
      Swal.fire({
        icon: "info",
        title: `Veuilliez vous connecter pour ajouter un produit au favoris .`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 6000,
      });
    } else {
      handleAddFavoris(id);
    }
  };

  const isFavorite = (id) => {
    console.log("id", id);
    let favorie = favoris?.find((x) => x?.produit?.id == id);

    if (favorie) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content pb-5">
        <div className="container-easy-market">
          <Fade bottom>
            <div
              className="content-back-to pb-5 pt-3"
              onClick={(e) => {
                e?.preventDefault();
                navigate(-1);
              }}
            >
              <HiOutlineArrowLeft style={{ fontSize: 18 }} />
              <span className="ps-2 go-back-btn">RETOUR</span>
            </div>
          </Fade>
          {isLoading && (
            <div className="row">
              <div className="col-lg-6 col-md-6 mb-3 dis-flex" key={0}>
                <div className="item-stat-dashboad-view ">
                  <div className="content-item-stat-dashboad-view mt-2">
                    <div className="content-item-icon-stat-dashboard-view">
                      <Skeleton className="img-icon-dash-view" width={`70%`} />
                      <Skeleton className="img-icon-dash-view" width={`70%`} />
                    </div>
                  </div>
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view">
                      <div className="icon-item-stat-dashboad-view">
                        <Skeleton
                          className="chiffre-item-stat-dashboad-view mb-1"
                          width={`50%`}
                        />
                        <Skeleton
                          className="chiffre-item-stat-dashboad-view mb-1"
                          width={`50%`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 mb-3 dis-flex" key={0}>
                <div className="item-stat-dashboad-view ">
                  <div className="content-item-stat-dashboad-view mt-2">
                    <Skeleton
                      className="titre-item-stat-dashboad-view mb-2"
                      width={`70%`}
                    />
                    <Skeleton
                      className="titre-item-stat-dashboad-view mb-2"
                      width={`50%`}
                    />
                    <Skeleton
                      className="titre-item-stat-dashboad-view mb-2"
                      width={`30%`}
                    />
                  </div>
                  <div className="content-stat-dashboard-view">
                    <div className="content-text-item-stat-dashboad-view">
                      <div className="icon-item-stat-dashboad-view">
                        <Skeleton
                          className="chiffre-item-stat-dashboad-view mb-1"
                          width={`50%`}
                        />
                        <Skeleton
                          className="titre-item-stat-dashboad-view mb-2"
                          width={`70%`}
                        />
                        <Skeleton
                          className="titre-item-stat-dashboad-view mb-2"
                          width={`50%`}
                        />
                        <Skeleton
                          className="chiffre-item-stat-dashboad-view mb-1"
                          width={`50%`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isLoading && (
            <div className="row row-product-item-page b-b-1 mb-3 pb-4">
              <div className="col-lg-6 col-md-12 col-left-product-item-page d-flex">
                <div className="content-col-left-product-item-page container-product-item-page">
                  <GaleryProduct
                    pictures={
                      data?.type == "taille_unique"
                        ? data?.images
                        : data?.variations && data?.variations[0]?.images
                          ? imagesProduit
                            ? imagesProduit
                            : data?.variations[0]?.images
                          : null
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-left-product-item-page d-flex">
                <div className="content-col-left-product-item-page container-product-item-page">
                  <Fade bottom>
                    <h1 className="name-prduct mb-2">{data?.nom}</h1>
                  </Fade>
                  <Fade bottom>
                    <p className="category-product-name mb-1">
                      Categorie: {data?.categorie?.nom}
                    </p>
                  </Fade>

                  <div className="custom_container container-product-description pb-4">
                    <Fade bottom>
                      <p className="custom_sm__title">Description</p>
                    </Fade>
                    <Fade bottom>
                      <p className="text-describe-product-item-detail py-3">
                        {data?.description}
                      </p>
                    </Fade>
                  </div>

                  <div className="custom_container container-product-price pb-4">
                    <Fade bottom>
                      {data?.promotion ||
                        data?.categorie?.promotion ||
                        data?.sous_categorie?.promotion ? (
                        <>
                          <span className="custom_sm__price_promos pb-3">
                            {formatCurrency(
                              data?.prix -
                              (data?.prix *
                                (data?.categorie?.promotion?.taux ||
                                  data?.sous_categorie?.promotion?.taux ||
                                  data?.promotion?.taux)) /
                              100,
                              Vendeur?.devise
                            )}
                          </span>
                          <span className="custom_sm__price_promos_bare pb-3">
                            {formatCurrency(data?.prix, Vendeur?.devise)}
                          </span>
                        </>
                      ) : (
                        <p className="custom_sm__price pb-3">
                          {formatCurrency(data?.prix, Vendeur?.devise)}
                        </p>
                      )}
                    </Fade>
                  </div>
                  {data?.variations?.length > 0 ? (
                    <div className="custom_container variation_content container-product-colors pb-4">
                      <Fade bottom>
                        <p className="m-0 pb-2 custom_sm__title">Couleur</p>
                      </Fade>
                      <Fade bottom>
                        <div className="d-flex align-items-center gap-3 avalaible_colors_wrapper">
                          {!!data?.variations &&
                            data?.variations
                              ?.filter((value, index, self) => {
                                return (
                                  self.findIndex(
                                    (v) =>
                                      v.couleur === value.couleur &&
                                      value.quantite > 0
                                  ) === index
                                );
                              })
                              ?.map((item, key) => (
                                <ColorVariationItem
                                  color={item.couleur}
                                  isActive={item.id}
                                  variation={item}
                                  key={key}
                                />
                              ))}
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    ""
                  )}
                  {data?.type != "taille_unique" &&
                    data?.type != "taille_unique_avec_couleur" ? (
                    <div className="custom_container variation_content container-product-sizes pb-3">
                      <Fade bottom>
                        <p className="m-0 pb-2 custom_sm__title">Taille</p>
                      </Fade>
                      <Fade bottom>
                        <div className="d-flex align-items-center gap-3 avalaible_sizes_wrapper">
                          {!!tailles &&
                            tailles.map((item, key) => (
                              <SizeVariationItem
                                size={item.taille}
                                variation={item}
                                key={key}
                              />
                            ))}
                        </div>
                      </Fade>
                    </div>
                  ) : (
                    ""
                  )}
                  {showValidation && (
                    <div className="alert alert-danger mt-1" role="alert">
                      Veillez choisir une taille
                    </div>
                  )}
                  <div className="container-quantite-produit mb-4">
                    <p className="title-quantity-product">Quantité</p>
                    {/* <div className="counter mt-3 mb-5">
                    <Counter setCount={setCount} count={count} produit={data} user={user} />
                  </div> */}
                    <div className="btn-wrap">
                      <button
                        className="btn decrement-btn btn-counter-item"
                        disabled={count <= 1}
                        onClick={(e) => decrement(e)}
                      >
                        −
                      </button>
                      <div className="num">{count}</div>
                      {count < data?.stock && (
                        <button
                          className="btn increment-btn btn-counter-item"
                          onClick={(e) => increment(e)}
                        >
                          +
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="container-quantite-produit">
                    <p className="title-quantity-product">Total</p>
                    <p className="title-quantity-product">
                      {formatCurrency(
                        (data?.promotion ||
                          data?.categorie?.promotion ||
                          data?.sous_categorie?.promotion
                          ? data?.prix -
                          (data?.prix *
                            (data?.categorie?.promotion?.taux ||
                              data?.sous_categorie?.promotion?.taux ||
                              data?.promotion?.taux)) /
                          100
                          : data?.prix) * count,
                        Vendeur?.devise
                      )}
                    </p>
                  </div>
                  <div className="container-btn-add-to-cart-product mt-4">
                    <div className="content-btn-commande w-100">
                      <button
                        className="btn btn-theme-fav-product w-100"
                        onClick={(e) => onToggleFavorite(data?.id)}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-1 d-inline-block"
                              role="status"
                            ></span>
                          </>
                        ) : isFavorite(data?.id) ? (
                          <AiFillHeart style={{ color: "#DB242A" }} />
                        ) : (
                          <AiOutlineHeart />
                        )}

                        <span className="ps-2">
                          {" "}
                          {isFavorite(data?.id)
                            ? "Supprimer de vos favoris"
                            : "Favoris"}
                        </span>
                      </button>
                    </div>
                    <div className="content-btn-commande w-100">
                      {data?.stock > 0 ? (
                        <button
                          className="btn btn-theme-annim w-100"
                          onClick={() =>
                            validation ? ajouterAuPanier() : handalevalidation()
                          }
                        >
                          <span className="pe-2">AJOUTER AU PANIER</span>
                          <FaShoppingCart />
                        </button>
                      ) : (
                        <button
                          className="btn btn-theme-annim w-100"
                          disabled={true}
                        >
                          <span className="pe-2">AJOUTER AU PANIER</span>
                          <FaShoppingCart />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <AvisProduct product={data} />
          <ProductSimilary product={data} />
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default ProduitItemPage;
