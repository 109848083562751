import * as React from "react";
import { useGetConfigAppQuery } from "../../../utils/api/config/config.api";
import { createMarkup } from "../../../utils/Utils";
import FooterPage from "../../auth/FooterPage";
import { AlertInfo } from "../../common/Alert";
import FrontFooter from "../FrontFooter/FrontFooter";
import FrontHeader from "../FrontHeader/FrontHeader";
import { TextSkeleton } from "../../admin/Parametres/Steps/CguAdmin";

const CguPage = () => {
  const { data, isLoading } = useGetConfigAppQuery();
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content py-5">
        <div className="container-easy-market">
          <div className="container">
            <div className="container-banner-policy">
              <h2 className="title-banner-policy">Règles et confidentialité</h2>
            </div>
            <div className="container-text-policy pb-5">
              {!!isLoading && <TextSkeleton />}
              {!isLoading &&
                (data?.cgu ? (
                  <p
                    className="text-policy"
                    dangerouslySetInnerHTML={createMarkup(data?.cgu)}
                  />
                ) : (
                  <AlertInfo message="Le contenu des cgu n'est pas encore disponible" />
                ))}

            </div>
          </div>
          <FooterPage />
        </div>
      </div>
      <FrontFooter />
    </div>
  );
};

export default CguPage;
