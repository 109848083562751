import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";


function PolitiqueConfidentialite() {
    return (
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content pb-5 pb-5">
                <h1 className="custom-page-title">
                    Politique de confidentialité
                </h1>
                <div className="container pb-4">
                    <div className="custom-politique-page-content pt-5">
                        <h3 className="custom-sm-title pb-1 pb-4">

                            Politique de Confidentialité
                        </h3>
                        <p className="update-date-content">
                            Dernière mise à jour : 25 Juillet 2024
                        </p>
                        <div className="content-all-politique pt-4 pb-3">
                            <div className="content-politique-item">
                                <h3 className="custom-sm-title pb-4">
                                    1. Introduction
                                </h3>
                                <div className="custom-sm-value">
                                    <strong>Univers Astro</strong>, accessible depuis <strong>universastro.easymarket.sn</strong> est une plateforme
                                    de commerce électronique qui permet aux utilisateurs d'acheter des produits et services en ligne. Cette politique
                                    de confidentialité décrit comment nous collectons, utilisons et partageons les informations personnelles des
                                    utilisateurs de notre plateforme.
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-2">
                                    2. Informations Collectées
                                </h3>
                                <div className="custom-sm-value pb-4">
                                    Nous collectons les informations personnelles des utilisateurs de la manière suivante :
                                </div>
                                <div className="custom-sm-value">
                                    <div className="content-politique-item">
                                        <h3 className="custom-sm-title pb-4">
                                            2.1 Informations fournies par les utilisateurs
                                        </h3>
                                        <div className="custom-sm-value">
                                            Lorsque vous utilisez <strong>Univers Astro</strong>, nous pouvons collecter les types de données personnelles suivants :
                                            <ul className="custom-instruction-list">
                                                <li className="listitem">
                                                    <strong>Informations d'identification :</strong> nom, prénom, adresse e-mail, numéro de téléphone, adresse postale.
                                                </li>
                                                <li className="listitem">
                                                    <strong>Informations de paiement :</strong> informations de carte de crédit ou de compte bancaire (le cas échéant).
                                                </li>
                                                <li className="listitem">
                                                    <strong>Informations de connexion :</strong> nom d'utilisateur, mot de passe et autres informations d'authentification.
                                                </li>
                                                <li className="listitem">
                                                    <strong>Informations de commande :</strong> détails de vos commandes, historique d'achat et informations de livraison.
                                                </li>
                                                <li className="listitem">
                                                    <strong>Informations de navigation :</strong> adresse IP, données de navigation, type de navigateur, préférences de langue.
                                                </li>
                                                <li className="listitem">
                                                    <strong>Informations sur l'appareil :</strong> type d'appareil, système d'exploitation, identifiant unique de l'appareil.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="content-politique-item pt-2">
                                        <h3 className="custom-sm-title pb-4">
                                            2.2 Informations collectées automatiquement
                                        </h3>
                                        <div className="custom-sm-value">
                                            Nous collectons automatiquement certaines informations lorsque les utilisateurs interagissent avec notre plateforme.
                                            Ces informations peuvent inclure l'adresse IP, le type de navigateur, le système d'exploitation, les pages visitées,
                                            et les actions effectuées sur notre plateforme.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    3. Utilisation des informations
                                </h3>
                                <div className="custom-sm-value">
                                    Nous utilisons les informations personnelles des utilisateurs aux fins suivantes :
                                    <ul className="custom-list">
                                        <li className="list-item">
                                            Pour fournir nos services : Nous utilisons les informations personnelles des utilisateurs pour fournir nos services, notamment
                                            pour traiter les commandes, livrer les produits, et fournir un support client.
                                        </li>
                                        <li className="list-item">
                                            Pour améliorer nos services : Nous utilisons les informations personnelles des utilisateurs pour améliorer nos services, particulièrement pour analyser les tendances, identifier les problèmes, et développer de nouvelles fonctionnalités.
                                        </li>
                                        <li className="list-item">
                                            Pour vous contacter : Nous pouvons utiliser les informations personnelles des utilisateurs pour les contacter, notamment pour les informer des changements apportés à nos services ou pour répondre à leurs questions ou demandes.
                                        </li>
                                        <li className="list-item">
                                            Pour vous envoyer des communications marketing : Nous pouvons utiliser les informations personnelles des utilisateurs pour leur envoyer des communications marketing, notamment des offres spéciales, des informations sur les produits et services, et des invitations à des événements.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    4. Partage des Informations
                                </h3>
                                <div className="custom-sm-value">
                                    Nous ne partageons les informations personnelles des utilisateurs qu'avec les tiers suivants :
                                    <ul className="custom-list">
                                        <li className="list-item">
                                            Nos partenaires commerciaux : Nous pouvons partager les informations personnelles des utilisateurs avec nos partenaires commerciaux qui nous aident à fournir nos services, notamment les sociétés de transport, les prestataires de services de paiement, et les sociétés d'analyse.
                                        </li>
                                        <li className="list-item">
                                            Les autorités publiques : Nous pouvons partager les informations personnelles des utilisateurs avec les autorités publiques si nous sommes tenus de le faire par la loi ou si nous pensons que cela est nécessaire pour protéger nos droits ou les droits de tiers.
                                        </li>
                                        <li className="list-item">
                                            Pour vous contacter : Nous pouvons utiliser les informations personnelles des utilisateurs pour les contacter, notamment pour les informer des changements apportés à nos services ou pour répondre à leurs questions ou demandes.
                                        </li>
                                        <li className="list-item">
                                            Pour vous envoyer des communications marketing : Nous pouvons utiliser les informations personnelles des utilisateurs pour leur envoyer des communications marketing, notamment des offres spéciales, des informations sur les produits et services, et des invitations à des événements.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    5. Sécurité des informations
                                </h3>
                                <div className="custom-sm-value">
                                    Nous prenons des mesures de sécurité pour protéger les informations personnelles des utilisateurs, notamment des mesures techniques et organisationnelles. Ces mesures comprennent des pare-feu, des contrôles d'accès, et des procédures de cryptage.
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    6. Droits des utilisateurs
                                </h3>
                                <div className="custom-sm-value">
                                    <ul className="custom-list">
                                        <li className="list-item">
                                            Le droit d'accès :  Les utilisateurs ont le droit d'accéder à leurs informations personnelles et de demander qu'elles soient mises à jour ou corrigées.
                                        </li>
                                        <li className="list-item">
                                            Le droit à l'effacement : Les utilisateurs ont le droit de demander que leurs informations personnelles soient effacées.
                                        </li>
                                        <li className="list-item">
                                            Le droit à la limitation du traitement : Les utilisateurs ont le droit de demander que le traitement de leurs informations personnelles soit limité.
                                        </li>
                                        <li className="list-item">
                                            Le droit à la limitation du traitement : Les utilisateurs ont le droit de demander que le traitement de leurs informations personnelles soit limité.
                                        </li>
                                        <li className="list-item">
                                            Le droit à la portabilité des données : Les utilisateurs ont le droit de recevoir leurs informations personnelles dans un format structuré et lisible par machine.
                                        </li>
                                        <li className="list-item">
                                            Le droit d'opposition :Les utilisateurs ont le droit de s'opposer au traitement de leurs informations personnelles pour des raisons légitimes.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    7. Contact
                                </h3>
                                <div className="custom-sm-value">
                                    Pour exercer ces droits, les utilisateurs peuvent nous contacter à l'adresse suivante : <br />
                                    <strong>+221 78 015 72 34</strong>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    8. Modifications de la Politique de Confidentialité
                                </h3>
                                <div className="custom-sm-value">
                                    Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment.
                                    Les modifications seront affichées sur notre site Web et vous serez informé des changements importants par d'autres moyens appropriés.
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <FrontFooter />
        </div>
    )
}

export default PolitiqueConfidentialite