import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import { TeamData } from "./data";
import { Link } from "react-router-dom";

const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

const TeamCard = ({
  photo,
  name,
  role,
  twitter,
  facebook,
  linkedin,
}: {
  photo?: string;
  name: string;
  role: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
}) => {
  return (
    <div className="team-card">
      <span className="photo-wrapper">
        <img loading="lazy" src={photo} alt="photo" />
      </span>
      <span className="name">{name}</span>
      <span className="role">{role}</span>
    </div>
  );
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-5 pb-5">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-4">
                <div className="illustration-container">
                  <img loading="lazy" src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">Univers Astro</h3>
                <div className="custom-content">
                  Univers Astro est une entreprise spécialisée en astrologie et en lithothérapie.
                  La lithothérapie vise à rééquilibrer les fonctions vitales et participe donc à une
                  harmonisation du corps et de l'esprit par la stimulation et la purification,
                  quant à l’astrologie c’est un miroir qui nous aide à nous connaître et à percevoir
                  nos propres forces et faiblesses en vue d’améliorer notre existence.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: '6rem 0' }}></div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
